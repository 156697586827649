import React, { Component } from 'react';
import AUX from '../../hoc/Aux_';
import {Row,Col,Form,FormGroup , Input , Label , Button ,Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';

class NotFound extends Component {
    render(){
        return(
            <>
                <Row>
                  <Col sm={12} >
                  NOT FOUND MDF!!!!!
                  </Col>
                </Row>
            </>
        )
    }


}
export default NotFound;
